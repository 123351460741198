
import './vendor/imagesloaded.pkgd.min.js';
import './vendor/masonry.pkgd.min.js';
import './vendor/lightbox.js';

( function() {

	( function() {
		// Cookie notice
		if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
			document.body.classList.add( 'show-cookie-notice' );
			document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000';
			document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
				document.body.classList.remove( 'show-cookie-notice' );
			} );
		}
	} )();

} )();